/**
 * MSAL configuration object. 
 * ClientId: The application or "Ouath 2.0 client" id.
 * RedirectUri: The URI to land on after successful sign in.
 * Scopes: Requested scopes at sign in
 * 
 * (Since we're using PKCE we dont need to supply the config object with an client secret.)
 */

const getRedirectUri = () => {

    if (process.env.REACT_APP_ENV === 'production') {
        return 'https://dmp.standbygroup.com/';
    }

    if (process.env.REACT_APP_ENV === 'test') {
        return 'https://dmp-test.standbygroup.com/';
    }

    return "http://localhost:3000"
}

export const msalConfig = {
    auth: {
        clientId: "d731717e-c40d-4345-bc1b-263d40e3722e",
        authority: "https://standbyb2c.b2clogin.com/standbyb2c.onmicrosoft.com/B2C_1_SIGN_UP_SIGN_IN",
        knownAuthorities: ["https://standbyb2c.b2clogin.com"],
        redirectUri: getRedirectUri(),
        scopes: ['https://standbyb2c.onmicrosoft.com/133e1b85-6f0c-4c29-ac0a-f6c7c48c082c/Vehicle.Read']
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    }
};
