import { createRoot } from 'react-dom/client';
import { applyPolyfills, defineCustomElements } from 'xml-viewer-component/dist/loader';
import App from "./App"
import "./index.css";
import './i18n/i18n';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);

// This polyfill enables XMLviewer 
applyPolyfills().then(() => {
    defineCustomElements(window);
});
