import { useMsal } from "@azure/msal-react";

export enum UserType {
    GUEST = 0,
    STANDBYUSER = 1,
    UPFITTER = 2,
    ENDCUSTOMER = 3,
}

export const useClaims = () => {
    const { accounts, instance } = useMsal();
    if (accounts[0])
        return accounts[0]!.idTokenClaims!;

    return {};
}