import React, { Suspense, FunctionComponent, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import {
  MsalProvider,
  MsalAuthenticationTemplate,
} from "@azure/msal-react";
import { useTranslation } from 'react-i18next';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config/auth.config";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import { InteractionType } from "@azure/msal-browser";
import { primaryTheme } from "./theme/primary.theme";
import ErrorBoundary from "./components/Common/ErrorBoundary";
import Header from "./components/Common/Header.Component";
import FullpageSpinner from "./components/Common/FullpageSpinner";
import Footer from "./components/Common/Footer.Component";

/* Containers */
const VehiclesContainer = React.lazy(() => import('./containers/Vehicles.Container'));
const UnitsContainer = React.lazy(() => import('./containers/Units.Container'));
const VehicleDetailContainer = React.lazy(() => import('./containers/VehicleDetail.Container'));
const SoftwareContainer = React.lazy(() => import('./containers/Software.Container'));
const UnitDetailContainer = React.lazy(() => import('./containers/UnitDetail.Container'));
const ActivateContainer = React.lazy(() => import('./containers/Activate.Container'));
const RegisterContainer = React.lazy(() => import('./containers/Register.Container'));
const AccessDeniedContainer = React.lazy(() => import('./containers/AccessDenied.Container'));

const App: FunctionComponent = () => {

  // Authentication 
  const msalInstance = new PublicClientApplication(msalConfig as any);

  // I18n
  const { t } = useTranslation();

  /*
  useEffect(() => {

    const expire_at = msalInstance.getActiveAccount()?.idTokenClaims?.exp;

    if (expire_at! < Date.now()) {
      msalInstance.logoutRedirect();
    }

  }, [msalInstance])
  */

  return (
    <Box sx={{
      minHeight: "100vh",
      margin: 0,
      display: "grid",
      gridTemplateRows: "auto 1fr auto",
    }}>
      <ThemeProvider theme={primaryTheme}>
        <MsalProvider instance={msalInstance}>
          <CssBaseline />
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <ErrorBoundary>
              <Router>
                <Header organizationName="" />
                <Suspense fallback={<FullpageSpinner text={t('loadingPage')} />}>
                  <Routes>
                    <Route path="/" element={<VehiclesContainer />} />
                    <Route path="/AccessDenied" element={<AccessDeniedContainer />} />
                    <Route path="/Vehicles" element={<VehiclesContainer />} />
                    <Route path="/Units" element={<UnitsContainer />} />
                    <Route path="/Software" element={<SoftwareContainer />} />
                    <Route path="/Activate" element={<ActivateContainer />} />
                    <Route path="/Register" element={<RegisterContainer />} />
                    <Route path="Unit">
                      <Route
                        path=":unitId"
                        element={<UnitDetailContainer />}
                      />
                    </Route>
                    <Route path="Vehicle">
                      <Route
                        path=":vehicleId"
                        element={<VehicleDetailContainer />}
                      />
                    </Route>
                  </Routes>
                </Suspense>
                <Footer />
              </Router>
            </ErrorBoundary>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </ThemeProvider>
    </Box>
  );
}

export default App;
