import { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import { useClaims, UserType } from "../../utils/user";

const Footer: FunctionComponent = () => {

  // User Claims
  const claims = useClaims();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", bottom: 0, minHeight: 80, mt: { md: 0, xs: 4 } }}>
      <Box sx={{ borderTop: "solid 1px #ccc", pt: 8, pb: 2, justifyContent: "center", display: "flex" }}>
        <Box sx={{ width: "50%", textAlign: "center" }}>
          Device Management Platform - Copyright © 2023 Standby AB
          <Box sx={{ alignSelf: "center", pb: 4, }}>
            {claims!["extension_UserEnum"] === UserType.STANDBYUSER &&
              <Box>
                {process.env.REACT_APP_ENV === "production" &&
                  <span style={{ marginRight: 20, color: "red" }}>(Environment: Production)</span>
                }
                {process.env.REACT_APP_ENV === "test" &&
                  <span style={{ marginRight: 20, color: "orange" }}>(Environment: Test)</span>
                }
                {process.env.REACT_APP_ENV === "development" &&
                  <span style={{ marginRight: 20, color: "green" }}>(Environment: Development)</span>
                }
                {process.env.REACT_APP_ENV === "local" &&
                  <span style={{ marginRight: 20, color: "darkgreen" }}>(Environment: Local)</span>
                }
              </Box>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default Footer;